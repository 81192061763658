import React from 'react';
import {
  Col, Divider, PageHeader, Row, Typography,
} from 'antd';
import { BaseLayout } from '../../components/layout/base/base-layout';
import { SEO } from '../../components/layout/seo';
import { useShop } from '../../graphql/static-hooks/useShop';

const {
  Title,
  Paragraph,
  Text,
} = Typography;

const TermsPage = () => {
  const shop = useShop();
  return (
    <BaseLayout>
      <SEO title="Terms & Conditions" />
      <Row align="center">
        <Col flex="650px">
          <PageHeader
            ghost={false}
            onBack={() => window.history.back()}
            title="Terms & Conditions"
          >
            <Divider />
            <Paragraph>
              <Text strong>
                Please carefully read
              </Text>
              .
            </Paragraph>
            <Title level={5}>
              GENERAL TERMS AND CONDITIONS OF USE
            </Title>
            <Paragraph>
              In this General Terms and Conditions of Use:
            </Paragraph>
            <Paragraph>
              <Text strong>
                {shop.name}
                , "we", "us", or "our"
              </Text>
              , means
              {' '}
              {shop.companyAddress?.companyName || 'Destination Global S.L.'}
              , Spanish company identified in section 1.
            </Paragraph>
            <Paragraph>
              <Text strong>
                "User", "You"
              </Text>
              , means a person who registers to the Website in order to search, browse, or request
              the
              booking of an Accommodation or its Services.
            </Paragraph>
            <Paragraph>
              <Text strong>
                "Client"
              </Text>
              , means a User that has booked and fully paid his/hers accommodation rental for a
              specific event.
            </Paragraph>
            <Paragraph>
              <Text strong>
                "Website"
              </Text>
              , means the site -
              <a href={shop.domain?.url}>
                {shop.domain?.host}
              </a>
            </Paragraph>
            <Paragraph>
              <Text strong>
                "Terms of Use"
              </Text>
              , means Website's General Terms and Conditions of Use.
            </Paragraph>
            <Paragraph>
              <Text strong>
                "Accommodation"
              </Text>
              , means an apartment, villa or hotel room.
            </Paragraph>
            <Paragraph>
              <Text strong>
                "Services"
              </Text>
              , depending on context could means the Website features and modules and also the other
              Services related to the booking of an Accommodation, concretely, transportation, maid
              and cleaning and other hospitality services.
            </Paragraph>
            <Paragraph>
              <Text strong>
                "Lists"
              </Text>
              , means a list of Accommodations available for event-time rentals and its Services.
            </Paragraph>
            <Paragraph>
              <Text strong>
                "Wishlists"
              </Text>
              , means a specific list of Accommodations selected by event, created by a User under
              his
              own profile.
            </Paragraph>
            <Paragraph>
              <Text strong>
                "Providers"
              </Text>
              , within the sense of these Terms of Use, means
              {' '}
              {shop.name}
              {' '}
              network of trusted professionals,
              accommodations and properties owners or managers.
            </Paragraph>
            <Paragraph>
              Any use of the above terminology or other words in the singular, plural, in uppercase,
              lowercase, or a combination, are taken as interchangeable and therefore as referring
              to
              same.
            </Paragraph>

            <Divider />

            <Paragraph>
              <Text strong>
                1. Identification
              </Text>
            </Paragraph>

            <Paragraph>
              <Text strong>
                1.1.
              </Text>
              {shop.companyAddress?.companyName || 'Destination Global S.L.'}
              , is a Spanish company with VAT number B-64497027, registered
              in
              the Mercantile Registry of Barcelona Volume: 39605, Folio: 8, Section: 8, Page:
              B351126,
              Entry: 1. We are based in Barcelona in a newly remodelled office in the heart of the
              city at Calle Ramon Turró 100-104, Attic 3, 08005, Barcelona.
              {' '}
              {shop.name}
              {' '}
              is a Community
              trademark, owned by
              {' '}
              {shop.companyAddress?.companyName || 'Destination Global S.L.'}
              , and through which the Accommodation and
              related Services offered on the website are marketed.
            </Paragraph>

            <Paragraph>
              <Text strong>
                1.2.
              </Text>
              {shop.name}
              {' '}
              is not a property owner but an intermediary consultancy, managing its own
              reliable and trusted professional network of accommodations providers controlled by
              their respective owners or property managers.
              {shop.name}
              {' '}
              activities, and thereof liability,
              are limited to providing information about Accommodation and Services for events and
              trade shows, offer the opportunity to make a booking if there is availability and then
              manage the entire process to exit, including but not limited to accepting offline
              payment from its Clients on behalf of its Providers.
            </Paragraph>

            <Paragraph>
              <Text strong>
                1.3.
              </Text>
              {shop.name}
              {' '}
              is a party to the Rental Agreement concluded between itself and its Clients and
              between itself and its network of reliable Providers. However, the Providers are
              themselves responsible for satisfying their own contractual obligations and adherence
              to
              the provisions governed by their national regulations regarding property rental and
              services related to this activity.
            </Paragraph>

            <Divider />

            <Paragraph>
              <Text strong>
                2. Basic Terms
              </Text>
            </Paragraph>

            <Paragraph>
              <Text strong>
                2.1.
              </Text>
              By using or accessing the Website, however accessed, you agree to be bound with these
              Terms of Use in their entirety and without any limitations. If you do not agree with
              these Terms of Use, or with any part thereof, we suggest that you refrain from
              accessing
              and using the Website and its services. Prior to requesting any Service, you will be
              required to explicitly accept our Terms of Use.
            </Paragraph>
            <Paragraph>
              <Text strong>
                2.2.
              </Text>
              These Terms of Use, together with our privacy policy, constitutes the framework
              agreement between you and
              {' '}
              {shop.name}
              {' '}
              and governs the use of the Website, superseding any
              prior agreement which regulated said purpose.
            </Paragraph>

            <Paragraph>
              <Text strong>
                2.3.
              </Text>
              You will have permanent, direct and free access to the public information on the
              Website. However, we reserve the right to restrict the access to certain specific
              content, information, sections and other features to registered Users as set in
              section
              4.
            </Paragraph>

            <Paragraph>
              <Text strong>
                2.4.
              </Text>
              If you are using the Services on behalf of a legal entity, you represent that you are
              authorized to enter into an agreement on behalf of that legal entity and, in such
              event,
              "You" "User" "Client" will refer and apply to that legal entity.
            </Paragraph>

            <Paragraph>
              <Text strong>
                2.5.
              </Text>
              Similarly, you are hereby notified that you must be of legal age in order to register
              on
              the Website, and by accepting these Terms of Use, you guarantee and declare that you
              are
              at least 18 years of age.
            </Paragraph>

            <Paragraph>
              <Text strong>
                2.6.
              </Text>
              Violation of these Terms of Use may, in
              {' '}
              {shop.name}
              {' '}
              sole discretion, result in termination of
              your
              {' '}
              {shop.name}
              {' '}
              Account referred in section 4.2.
            </Paragraph>

            <Divider />

            <Paragraph>
              <Text strong>
                3. Authorized Use
              </Text>
            </Paragraph>

            <Paragraph>
              You are obligated to use the Website and its contents and/or Services diligently and
              correctly, agreeing to not use it:
            </Paragraph>

            <Paragraph>
              - To carry out activities against the law, morality, the public order, and instead, to
              use it according to these Terms of Use.
            </Paragraph>

            <Paragraph>
              - For illegal or forbidden purposes or to harm the rights or interests of third
              parties,
              infringing Copyright regulations, or whatever other regulations that might be
              applicable.
            </Paragraph>

            <Paragraph>
              By using the Website and its Services, you agree not to use it for any other purpose
              than finding, enquire, book and manage an Accommodation or related Services.
            </Paragraph>

            <Divider />

            <Paragraph>
              <Text strong>
                4. General Booking Terms
              </Text>
            </Paragraph>

            <Paragraph>
              <Text strong>
                4.1. The Website
              </Text>
            </Paragraph>
            <Paragraph>
              {shop.name}
              {' '}
              provides an online platform providing professional event attendees with the
              ability to browse, search, wishlist, enquire and book event-time Accommodations and
              related Services directly on the platform. As a User, for viewing a search result
              listing does not require prior registration, however, if you wish to wishlist or book
              an
              Accommodation, you must first register and create an account -profile ("
              {shop.name}
              Account"). The Website does not include an online platform for payments, being such
              transactions done under the specific terms indicated in the Rental agreement and in
              all
              cases by any means offline.
            </Paragraph>

            <Paragraph>
              <Text strong>
                4.2. Registration
              </Text>
            </Paragraph>

            <Paragraph>
              Registration is required in order to use the Website and its Services. In order to
              save
              your searches, wishlists, enquiries or book an Accommodation through the Website, you
              must previously register, free of charge, to create an
              {' '}
              {shop.name}
              {' '}
              Account, fill in your
              profile and contact information in order to finalise any Accommodation or Services
              enquires or bookings. Confirmation of registration through email verification
              constitutes the User agreement. Once you activate your
              {' '}
              {shop.name}
              {' '}
              Account clicking on the
              link on the confirmation email, you will be able to complete your Profile from the
              dashboard and start wishlisting your event accommodation.

            </Paragraph>

            <Paragraph>
              {shop.name}
              {' '}
              reserves the right to terminate or suspend your Account and access to the Website
              and Services if you create more than one
              {shop.name}
              {' '}
              Account (you may not have more than one
              active
              {shop.name}
              {' '}
              Account), or attempt to register in the name of any other individual or
              legal entity without their express authorization.

            </Paragraph>

            <Paragraph>
              You agree to provide truthful, accurate, current and complete information, and to
              update
              such information to keep it accurate and complete at all time. All personal
              information
              supplied by you as part of the registration process or completing your
              {' '}
              {shop.name}
              {' '}
              Account,
              at any stage and time, will be protected and used in accordance with our Privacy
              Policy.
            </Paragraph>

            <Paragraph>
              <Text strong>
                4.3. Availabilities
              </Text>
            </Paragraph>

            <Paragraph>
              When searching for Accommodations for the event you are planning to attend by entering
              their conference, fair, trade show or congress name, the search results page will
              display all the Accommodations currently available for such event. Also
              {' '}
              {shop.name}
              {' '}
              does its
              best to provide real-time availabilities, once a User has enquired for an
              accommodation
              (or several) availability(ies),
              {' '}
              {shop.name}
              {' '}
              will have to get back to the User, prior to any
              booking, confirming price and availability with its Providers. The final booking
              confirmation will be sent by
              {' '}
              {shop.name}
              {' '}
              Support Team solely once the payment for such rental
              has been received in accordance with the General Terms and Conditions of the Rental
              Agreement that you have previously accepted through the Website and that you must
              return
              signed to
              {' '}
              {shop.name}
              , by means of postal or electronic mail indicated in these Terms of Use.
            </Paragraph>

            <Paragraph>
              Availabilities displayed are for the conference, fair, trade show or congress week
              dates. In the case where a User is willing to book an Accommodation outside the event
              dates (e.g.: requiring an extra day before or after the event), he/she will have the
              possibility through the Accommodation Comments, live chat or email, to enquire for
              extra
              rental days.
              {' '}
              {shop.name}
              {' '}
              will inform such User of the possibility of a longer rental period
              of the considered Accommodation after confirming it with their Provider.
            </Paragraph>

            <Paragraph>
              Receiving information about price and availabilities, by any electronics means, shall
              not be deemed as an offer acceptance nor be legally binding between the parties.

            </Paragraph>

            <Paragraph>
              <Text strong>
                4.4. Wishlists
              </Text>
            </Paragraph>

            <Paragraph>
              Within the Website and its Services, Users are able to create, build and manage their
              Accommodations wishlists. Such wishlists will never be made publicly available on the
              Website. Therefore, Users agree they are solely responsible for their wishlists and
              related content. Through these wishlists of Accommodations that you created for each
              corporate event, you will be able to ask, directly against each selected property, any
              questions, doubts about such properties and later receive the feedback from
              {' '}
              {shop.name}
              Support Team, as well as enquiring about a property availability and price. Once the
              User has enquired for the properties wishlisted and received
              {' '}
              {shop.name}
              's Support Team
              answers, price and availability, he/she will be able to request and confirm the
              Accommodation booking.
            </Paragraph>

            <Paragraph>
              <Text strong>
                4.5. Bookings
              </Text>
            </Paragraph>

            <Paragraph>
              As previously mentioned above, Users will only be invoiced for the Accommodations they
              confirm their interest for after previously receiving price and availability. Please
              remember, we can not guarantee availability and price over time, once you received
              price
              and availability, simply go back to your wishlist and tick the "Add to booking button"
              and proceed to confirm. Our Support Team will get back to you with a booking
              confirmation as soon as possible. Rental Agreement does not become effective until
              confirmation of availability (condition precedent).
            </Paragraph>

            <Paragraph>
              Please note that failure to comply with payment obligations assumed under the Rental
              Agreement, or any other obligations affecting compliance, shall constitute a breach of
              contract and automatically implies its termination.

            </Paragraph>

            <Paragraph>
              Your booking details are always accessible on
              {' '}
              {shop.name}
              . You can download your invoices and
              rental agreements, review your reservation history, view instructions for arriving to
              the apartment or hotel, and more.

            </Paragraph>

            <Paragraph>
              The language used in all contractual documents and procedures is English.
            </Paragraph>

            <Paragraph>
              <Text strong>
                4.6. Providers

              </Text>
            </Paragraph>

            <Paragraph>
              {shop.name}
              's network of trusted professionals, accommodations and properties owners or
              managers, are verified by
              {shop.name}
              {' '}
              as a legally registered business or entity offering
              short-term rentals during a specific event, being an apartment, villa or hotel room;
              as
              well as businesses or persons providing a service to
              {shop.name}
              's website such as hosting,
              maintenance, etc.

            </Paragraph>

            <Paragraph>
              Descriptions of locations, Accommodations whether given online, in a brochure, by
              letter, word of mouth, or email are presented by
              {' '}
              {shop.name}
              {' '}
              on its Website in good faith
              based on the latest information received from our trusted affiliates-providers and is
              the belief that they are true but shall not constitute any representations by
              {' '}
              {shop.name}
              other than that
              {' '}
              {shop.name}
              {' '}
              honestly held such belief at the time.
              {' '}
              {shop.name}
              {' '}
              shall not be held
              liable by the Clients or Users for any discrepancy thereto or for any modifications
              made
              thereto by the owner or property manager without the knowledge of
              {' '}
              {shop.name}
              .
            </Paragraph>

            <Paragraph>
              <Text strong>
                4.7. Payments
              </Text>
            </Paragraph>

            <Paragraph>
              Once
              {' '}
              {shop.name}
              's Support Team has confirmed availability, and the User have accepted the
              Terms of the Rental Agreement, a Fifty per cent (50%) of the total invoice will be
              required and payable at the time of booking (24 hours). Should the booking be made
              within 10 weeks of the rental date, one hundred per cent (100%) of the total invoice
              is
              due in the same terms aforementioned.

            </Paragraph>

            <Paragraph>
              The payments or any other monies held on account by
              {' '}
              {shop.name}
              {' '}
              do not accrue interest.

            </Paragraph>

            <Paragraph>
              Full payment of the invoice must be received ten (10) weeks before your arrival. If
              the
              balance is not received by us by the due date, we shall be entitled to cancel the
              booking without prejudice to any claim for cancellation charges, and to retain any
              deposit received. For bookings made within ten (10) weeks of the arrival date, the
              client will be required to pay the full cost, plus any security or damage deposit, at
              time of booking.

            </Paragraph>

            <Paragraph>
              <Text strong>
                4.8. Security Deposits
              </Text>
            </Paragraph>

            <Paragraph>
              For each of our properties, we are required to request a deposit to cover any
              potential
              damages to the furniture, appliances or any other items in the rented premises. The
              Security Deposit is due 10 weeks before the event, along with your second payment of
              the
              invoice.

            </Paragraph>

            <Paragraph>
              <Text strong>
                4.9. Price
              </Text>
            </Paragraph>

            <Paragraph>
              Prices displayed on the Website or sent privately as an answer to any User enquiry, do
              not include the security deposit, utilities, tourist tax and in some cases the exit
              cleaning. Prices for the same Accommodation may vary from one event or from one year
              to
              another for the same Accommodation. The provided price for an Accommodation includes
              the
              renting period cost and a clean Accommodation with clean linens.
            </Paragraph>

            <Paragraph>
              The "Budget" rating of a property is determined by a combination of factors including
              its size, standard and location.
              {' '}
              {shop.name}
              {' '}
              tries very hard to estimate an accurate budget
              rating for a property, but as prices can change drastically from event to event, it is
              impossible to provide a general estimated price. Nonetheless we will always inform you
              with the price before booking an accommodation and entering into a contract with us.

            </Paragraph>

            <Paragraph>
              If any of these provisions are inconsistent with any term of the Rental Agreement, the
              latter shall prevail.

            </Paragraph>

            <Divider />

            <Paragraph>
              <Text strong>
                5. Limitation of Liability

              </Text>
            </Paragraph>

            <Paragraph>
              <Text strong>
                5.1.
              </Text>
              {shop.name}
              {' '}
              shall not be responsible for and disclaims all liability for any loss, liability,
              damage, including without limitation, direct, indirect or consequential, expense of
              any
              nature whatsoever which may be suffered by the User or any third party (including the
              User's company), as a result of or which may be attributable, directly or indirectly,
              to
              the User's access, use, inability to use, or performance of the Website, any
              information
              contained on the Website, or material and information transmitted over our system.
              {' '}
            </Paragraph>

            <Paragraph>
              <Text strong>
                5.2.
              </Text>
              We may provide access by means of hyperlinks to other sites on the internet (the
              "third-party websites") that we think might be of interest. We do not monitor or
              review
              the content of third-party websites which are linked to from the Website. Opinions
              expressed or materials appearing on such websites are not necessarily shared or
              endorsed
              by us and should not be regarded as the publisher of such opinions or material. Please
              be aware that we are not responsible for the privacy practices, or content, of these
              third-party websites. We encourage our Users to be aware when they leave our Website
              and
              to read the privacy statements of these third-party websites. You should evaluate the
              security and trustworthiness of any other site connected to or accessed through this
              Website yourself, before disclosing any personal data to them. We will not accept any
              responsibility for any loss or damage in whatever manner, howsoever caused, resulting
              from your disclosure to third parties of personal information or for any poor
              functioning or damages that may occur as a result of using, accessing or browsing said
              third-party websites.
              {' '}
            </Paragraph>

            <Divider />

            <Paragraph>
              <Text strong>
                6. Disclaimer of Warranties
              </Text>
            </Paragraph>

            <Paragraph>
              <Text strong>
                6.1.
              </Text>
              Although we strive to ensure that the information in the Website is correct, we do not
              represent or warrant its integrity or exactness. We try by all technical means
              possible
              that the Website is in optimum operation, however, given the nature of digital means,
              incidents may occur which are beyond our control and which make it impossible to
              ensure
              that the Website remains available, as we will need to carry out routine maintenance
              and
              servicing of our servers and equipment from time to time, or that the contents of the
              Website are kept up to date or error-free.
              {' '}
            </Paragraph>

            <Paragraph>
              <Text strong>
                6.2.
              </Text>
              In any case, we disclaim any responsibility or liability to any person or entity for
              any
              loss or damage, injury, claim, or other cause of any kind or character based upon or
              resulting from delays or failures produced in the access, functioning and operation of
              the Website, or in its Services and/or contents, as well as also due to interruptions,
              suspensions or the poor functioning of same.
              {' '}
            </Paragraph>

            <Paragraph>
              <Text strong>
                6.3.
              </Text>
              {shop.name}
              {' '}
              does not warrant the absence of a virus or other similar harmful elements on the
              Website and/or the servers where it is hosted. However, we have adopted all the
              necessary measures which are possible under the current technology to guarantee and
              avoid the existence or the transmission of viruses and/or any other type of harmful
              software through the Website.
              {' '}
            </Paragraph>

            <Paragraph>
              <Text strong>
                6.4.
              </Text>
              Users are solely responsible for the content they publish, post on the Website. By
              accepting our Terms of Use and using the Website and its Services, Users acknowledge
              and
              agree that at no time their account may be used, passed on to a Third Party and that
              they are solely responsible for their password confidentiality.

            </Paragraph>

            <Divider />

            <Paragraph>
              <Text strong>
                7. Intellectual Property
              </Text>
            </Paragraph>

            <Paragraph>
              <Text strong>
                7.1.
              </Text>
              The contents, including but not limited to, the texts, comments, illustrations,
              images,
              guides, corporate logos and source code of the Website, are property of
              {' '}
              {shop.name}
              {' '}
              and when
              applicable, of the entities or persons indicated herein (licensors), all of them being
              protected by copyright, trademark and other laws of Spain and Community and
              International regulations which may be applicable.

            </Paragraph>

            <Paragraph>
              <Text strong>
                7.2.
              </Text>
              Some photographs included on the Website are granted to us under a non-exclusive
              world-wide license in perpetuity by our Providers or the property owners/ managers.

            </Paragraph>

            <Paragraph>
              <Text strong>
                7.3.
              </Text>
              The reproduction, distribution, marketing, commercialization, transformation, copy or
              any other act of electronic marketing is strictly prohibited without the expressed
              prior
              authorization of
              {' '}
              {shop.name}
              .

            </Paragraph>

            <Divider />

            <Paragraph>
              <Text strong>
                8. Changes in the Terms of Use
              </Text>
            </Paragraph>

            <Paragraph>
              <Text strong>
                8.1.
              </Text>
              We reserve the right to modify, either in whole or in part, these Terms of Use, with
              the
              new ones that are agreed upon becoming effective as of the date of their publication
              on
              the Website. Therefore, we recommend that you carefully read and regularly review
              these
              Terms of Use so that you may be familiar with the updated version.

            </Paragraph>

            <Paragraph>
              <Text strong>
                8.2.
              </Text>
              If any provision of these Terms of Use is held to be unlawful, void, or for any reason
              unenforceable by a court of competent jurisdiction, then that provision will be deemed
              severable from these Terms of Use and will not affect the validity and enforceability
              of
              any remaining provisions.

            </Paragraph>

            <Divider />

            <Paragraph>
              <Text strong>
                9. Privacy Policies
              </Text>
            </Paragraph>

            <Paragraph>
              We can use and share your information in accordance with our Privacy Policy. Our
              privacy
              policy is considered to be part of these Terms of Use.
            </Paragraph>

            <Divider />

            <Paragraph>
              <Text strong>
                10. Governing Law and Dispute Resolution
              </Text>
            </Paragraph>

            <Paragraph>
              The Terms of Use stipulated in this document are governed by Spanish Law. The parties
              expressly renounce any other jurisdiction and submit themselves to the Courts and
              Tribunals of the City of Barcelona, for the purpose of resolving any controversy or
              legal dispute which could arise. This dispute resolution provision will survive the
              termination of any or all of your transactions with
              {' '}
              {shop.name}
              .

            </Paragraph>

            <Divider />

            <Paragraph>
              <Text strong>
                11. Contact Us
              </Text>
            </Paragraph>

            <Paragraph>
              Should you have any questions regarding these Terms of Use, you can contact us by
              Telephone:
              {' '}
              {shop.companyAddress?.phone}
              , or by Email -
              <a href={shop.defaultMailSenderAddress}>
                {shop.defaultMailSenderAddress}
              </a>

            </Paragraph>

            <Paragraph>
              Latest Version, May 2016
            </Paragraph>

          </PageHeader>
        </Col>
      </Row>

    </BaseLayout>
  );
};

export default TermsPage;
